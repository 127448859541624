<template>
  <div class="overflow-hidden">
    <v-app-bar
      style="background-color:#424242 !important;"
      :dark="$vuetify.theme.dark"
      class="px-sm text-left shadow-sm"
      flat
      height="200"
      absolute
      prominent
    >
      <v-container class="d-flex align-center mt-6">
        <v-progress-linear
          :active="getThemeMode.isLoading"
          :indeterminate="getThemeMode.isLoading"
          absolute
          bottom
          color="primary"
        />

        <v-toolbar-title style="margin-top:-20px">
          <v-avatar
            height="95"
            width="95"
          >
            <img
              src="@/assets/images/logo.svg"
              alt="avatar"
            >
          </v-avatar>
        </v-toolbar-title>

        <v-spacer />
        <v-menu
          offset-y
          min-width="150"
        >
          <template v-slot:activator="{on, attrs}">
            <v-btn
              color
              dark
              v-bind="attrs"
              icon
              v-on="on"
            >
              <v-avatar size="30">
                <img
                  src="@/assets/images/avatars/001-man.svg"
                  alt
                >
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-avatar size="80">
                  <img
                    src="@/assets/images/avatars/001-man.svg"
                    alt
                  >
                </v-avatar>
                {{ nameuser }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-button
                  style="cursor:pointer;"
                  @click="signOut"
                >
                  <v-icon
                    class="mr-1"
                  >
                    mdi-logout
                  </v-icon>Cerrar Sesión
                </v-button>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'VerticallAppBar',
    computed: {
      ...mapGetters(['getThemeMode']),
    },
    data () {
      return {
        nameuser: JSON.parse(atob(localStorage.getItem('uid'))).nameUser,
      }
    },
    methods: {
      ...mapActions([
        'changeVerticalSidebarDrawer',
        'changeVerticalSidebarMini',
        'logout',
      ]),
      toggleVerticalSidebarDrawer () {
        this.changeVerticalSidebarDrawer()
      },
      signOut () {
        this.logout()
        this.$router.push('/app/sessions/sign-in')
      },
    },
  }
</script>
